<template>
  <div class="page">
    <div class="input-wrap">
      <van-field class="inp" v-model="userName" label="用户名" placeholder="请输入用户名" />
    </div>
    <div class="input-wrap mgt20">
      <van-field class="inp" v-model="password" type="password" label="密码" placeholder="请输入密码" />
    </div>
    <van-button class="btn mgt20" type="primary" @click="onLogin">登录</van-button>
  </div>
</template>

<script>
// import { Toast } from "vant";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { Toast } from "vant";

export default {
  name: "login",
  computed: {
    ...mapGetters(["agentId", "isLogin"]),
  },
  data() {
    return {
      userName: null,
      password: null,
      url: 'https://api.ybrsmfw.cn/amb/admin/agent/login'
    }
  },
  methods: {
    ...mapActions(["setLoginAction"]),
    onLogin() {
      /*if (!this.userName) {
        Toast("请输入用户名");
        return true;
      }
      if (!this.password) {
        Toast("请输入密码");
        return true;
      }*/
      axios({
        url: "https://api.ybrsmfw.cn/amb/admin/agent/login",
        method: "post",
        data: {
          username: this.userName,
          password: this.password
        }
      }).then(res => {
        if (res.data.success) {
          this.setLoginAction({
            loginStatus: true,
            userInfo: res.data.result,
            agentId: res.data.result.channelNo
          });
          localStorage.setItem("loginStatus", true);
          localStorage.setItem("agentId", res.data.result.channelNo);
          localStorage.setItem("userInfo", JSON.stringify(res.data.result));
          Toast('登录成功');
          setTimeout(() => {
            this.$router.push("/");
          },1000)
        } else {
          Toast(res.data.message);
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .input-wrap {
    width: 90%;
    height: 50px;
    background-color: #F9F9F9;
    border-radius: 4px;
    .inp {
      height: 100%;
      display: flex;
      align-items: center;
      background-color: #F9F9F9;
    }
  }
  .btn {
    width: 80%;
    border-radius: 16px;
  }
  .mgt20 {
    margin-top: 20px;
  }
}
</style>
